import React, { FC } from 'react';
import styled from 'styled-components';
import { DynamicImage, Text } from 'components';
import { mobile, sxMobile } from 'styles/breakpoints';

interface TitleTextImageProps {
  title: string;
  text: string;
  imageTitle: string;
  image: string;
  isMain: boolean;
  imageMain?: string;
}

const StyledTitle = styled(Text)`
  width: 100%;
  max-width: 35rem;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  color: #fff;
  @media ${mobile} {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
`;

const StyledText = styled(Text)`
  font-feature-settings: 'clig' off, 'liga' off;
  width: 100%;
  max-width: 35rem;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 200;
  line-height: 140%;
  color: #fff;
  @media ${mobile} {
    font-size: 1rem;
  }
`;

const StyledImageTitle = styled(Text)`
  font-feature-settings: 'clig' off, 'liga' off;
  text-align: center;
  font-family: Mulish;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 800;
  line-height: 140%;
  color: #f47170;
`;

const StyledImage = styled(DynamicImage)`
  width: 35rem;

  @media ${mobile} {
    width: 100%;
    min-width: 21rem;
  }
  @media ${sxMobile} {
    width: 100%;
    min-width: 18rem;
  }
`;

const ImageContainer = styled.div`
  margin: 3rem 0;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${mobile} {
    padding: 0 1rem;
  }
`;

const TitleTextImage: FC<TitleTextImageProps> = ({
  title,
  text,
  imageTitle,
  image,
  imageMain,
  isMain,
}) => (
  <Container>
    <StyledTitle>{title}</StyledTitle>
    <StyledText dangerouslySetInnerHTML={{ __html: text }} />
    <ImageContainer>
      <StyledImageTitle>{imageTitle}</StyledImageTitle>
      <StyledImage src={isMain ? (imageMain as string) : image} alt="cell" />
    </ImageContainer>
  </Container>
);

export default TitleTextImage;
