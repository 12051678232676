import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { mobile, tablet, useQuery } from 'styles/breakpoints';

import { DynamicImage, Text } from 'components';
import ChecklistItem from './ChecklistItem';
import Button from './StartNowButton';
import { AppState } from 'state/types';
import { useSelector } from 'react-redux';

interface GraphAndTypeProps {
  label: string;
  title: string;
  typeTitle: string;
  typeAnswerKey: string;
  typeLabel: string;
  typeList: string[];
  imageTitle: string;
  imageUrl: string;
  alt: string;
  buttonTitle: string;
  onClick: () => void;
  typeOptions: TypeOption[];
}

interface TypeOption {
  title: string;
  whitelist: string[];
  blacklist: string[];
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 1rem;
  @media ${tablet} {
    padding: 1.5rem 1rem;
  }
`;

const ContainersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media ${mobile} {
    flex-direction: column-reverse;
  }
`;

const ContentContainer = styled.div<{ hasMargin?: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.cultured};
  padding: 1.5rem 2rem;
  border-radius: 16px;
  max-width: 33.1875rem;
  width: 100%;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.08);
  margin-right: ${({ hasMargin }) => (hasMargin ? '2rem' : '0rem')};
  @media ${mobile} {
    margin-right: 0rem;
    margin-bottom: ${({ hasMargin }) => (hasMargin ? '1.5rem' : '0rem')};
    padding: 1rem 1.5rem;
  }
`;

const Label = styled(Text)`
  margin-bottom: 1rem;
  text-align: center;
  @media ${tablet} {
    margin-bottom: 0.5rem;
  }
`;

const Title = styled(Text)`
  text-align: center;
  margin-bottom: 3rem;
  @media ${tablet} {
    margin-bottom: 1.5rem;
  }
`;

const ImageTitle = styled(Text)`
  margin-bottom: 1rem;
  text-align: center;
`;

const TypeTitle = styled(Text)`
  text-align: center;
  margin-bottom: 1rem;
  @media ${tablet} {
    margin-bottom: 0.75rem;
  }
`;

const Type = styled(TypeTitle)`
  font-size: 2.25rem;
  @media ${mobile} {
    font-size: 1.625rem;
  }
`;

const Divider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.light100};
  margin-bottom: 1rem;
`;

const TypeListLabel = styled(TypeTitle)`
  text-align: start;
`;

const StyledButton = styled(Button)`
  margin-top: 3rem;

  @media ${mobile} {
    margin-top: 1.5rem;
  }
`;

const GraphAndType: FC<GraphAndTypeProps> = ({
  label,
  title,
  imageUrl,
  imageTitle,
  typeTitle,
  typeAnswerKey,
  typeLabel,
  typeList,
  buttonTitle,
  onClick,
  typeOptions,
  alt,
}) => {
  const { quiz_answers } = useSelector((state: AppState) => state.user);
  const { isMobile } = useQuery();

  const typeAnswers: string[] =
    useMemo(
      () => quiz_answers[typeAnswerKey]?.split('|'),
      [quiz_answers, typeAnswerKey],
    ) || [];

  const renderType = () => {
    let typeOptionIndex = typeOptions?.findIndex(opt => {
      const passedWhitelist = opt.whitelist.some((w: string) =>
        typeAnswers.some(a => a === w),
      );
      const passedBlacklist = opt.blacklist.some((w: string) =>
        typeAnswers.some(a => a === w),
      );
      return passedWhitelist && !passedBlacklist;
    });
    // if options don't pass whitelist & !blacklist, fallback to last option
    if (typeOptionIndex === -1) {
      typeOptionIndex = typeOptions.length - 1;
    }
    return typeOptions?.[typeOptionIndex]?.title || '';
  };

  const renderList = (item: string, index: number) => (
    <ChecklistItem
      label={item}
      key={index}
      marginBot={index + 1 !== typeList.length}
    />
  );
  return (
    <Container>
      <Label>{label}</Label>
      <Title type={isMobile ? 'countS' : 'h13Rem'}>{title}</Title>
      <ContainersWrapper>
        <ContentContainer hasMargin={!isMobile}>
          <ImageTitle type={isMobile ? 'body700' : 'h2'}>
            {imageTitle}
          </ImageTitle>
          <DynamicImage src={imageUrl} alt={alt} />
        </ContentContainer>
        <ContentContainer hasMargin={isMobile}>
          <TypeTitle type={isMobile ? 'body700' : 'h2'}>{typeTitle}</TypeTitle>
          <Type type={isMobile ? 'countS' : 'h2'} color="green">
            {renderType()}
          </Type>
          <Divider />
          <TypeListLabel type={isMobile ? 'bodyM' : 'h3400'}>
            {typeLabel}
          </TypeListLabel>
          {typeList.map(renderList)}
        </ContentContainer>
      </ContainersWrapper>
      <StyledButton onClick={onClick}>{buttonTitle}</StyledButton>
    </Container>
  );
};

export default GraphAndType;
