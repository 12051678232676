import React, { useCallback } from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import { DynamicImage, Text } from 'components';
import ContentContainer from './ContentContainer';
import CheckItem from 'components/CheckItem';
import { tablet, useQuery } from 'styles/breakpoints';

interface AppActionsProps {
  title: string;
  list: string[];
  img: string;
  alt: string;
}

const IconContainer = styled.div`
  width: 32.62rem;
  margin-right: 1rem;

  @media ${tablet} {
    max-width: 32.62rem;
    width: 100%;
    margin-bottom: 2rem;
  }
`;

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.light60};
  padding: 4rem 0;

  @media ${tablet} {
    padding: 3rem 0;
  }
`;

const Content = styled(ContentContainer)`
  display: flex;
  justify-content: space-between;
  margin-top: 0;
  margin-bottom: 0;

  @media ${tablet} {
    flex-direction: column;
    align-items: center;
  }
`;

const ListWrap = styled.div`
  width: 32.6rem;
  margin: auto 0;

  @media ${tablet} {
    width: auto;
    margin: auto;
  }
`;

const ListContainer = styled.div`
  margin-top: 3rem;

  div:nth-child(1n):not(:last-child) {
    margin-bottom: 1.58rem;
  }
`;

const AppActions: FC<AppActionsProps> = ({ title, list, img, alt }) => {
  const { isMobile } = useQuery();

  const renderItem = useCallback((item: string, index: number) => {
    const key = `key-${index}`;
    return <CheckItem key={key}>{item}</CheckItem>;
  }, []);

  return (
    <Container>
      <Content>
        <IconContainer>
          <DynamicImage src={img} alt={alt} />
        </IconContainer>
        <ListWrap>
          <Text type={isMobile ? 'countS' : 'count'} color="dark100">
            {title}
          </Text>
          <ListContainer>{list.map(renderItem)}</ListContainer>
        </ListWrap>
      </Content>
    </Container>
  );
};

export default AppActions;
