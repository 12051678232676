import React, { FC } from 'react';
import styled from 'styled-components';

import { Text } from 'components';
import { tablet, useQuery } from 'styles/breakpoints';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';
import Button from './StartNowButton';

interface SliderBenefitsProps {
  title: string;
  benefits: Benefit[];
  onClick: () => void;
  getProgramBtnTitle: string;
}

interface Benefit {
  label: string;
  multiplier: number;
  unitLeft?: string;
  unitRight?: string;
  minuendKey: string;
  subtrahendKey?: string;
}

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.lightGreen};
  padding: 4.35rem 1rem;
  @media ${tablet} {
    padding: 3rem 1rem 2rem 1rem;
  }
`;

const Title = styled(Text)`
  text-align: center;
  margin-bottom: 2rem;
`;

const BenefitsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  @media ${tablet} {
    flex-direction: column;
  }
`;

const BenefitContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 71.25rem;
  flex-basis: 33%;
  @media ${tablet} {
    margin-bottom: 1rem;
  }
`;

const BenefitItem = styled(Text)`
  text-align: center;
  font-size: 2.25rem;
`;

const BenefitLabel = styled(Text)`
  text-align: center;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 1.25rem 0 1rem 0;
`;

const SliderBenefits: FC<SliderBenefitsProps> = ({
  title,
  benefits,
  getProgramBtnTitle,
  onClick,
}) => {
  const { isMobile } = useQuery();
  const { quiz_answers } = useSelector((state: AppState) => state.user);

  const renderBenefit = (benefit: Benefit, index: number) => {
    const minKey = benefit.minuendKey;
    const subKey = benefit.subtrahendKey;
    const minVal = Number(quiz_answers[minKey]);
    const subVal = Number(subKey ? quiz_answers[subKey] : 0);
    const diff = minVal - subVal;
    const multiplier = benefit?.multiplier || 1;
    const value = diff * multiplier;
    return (
      <BenefitContainer key={index}>
        <BenefitItem type={'h2'} color="darkBlue">
          {benefit.unitLeft}
          {value.toLocaleString('en-US')}
          {benefit.unitRight}
        </BenefitItem>
        <BenefitLabel type={isMobile ? 'body' : 'h3400'}>
          {benefit.label}
        </BenefitLabel>
      </BenefitContainer>
    );
  };
  return (
    <Container>
      <Title type={isMobile ? 'h3' : 'h2'}>{title}</Title>
      <BenefitsContainer>{benefits.map(renderBenefit)}</BenefitsContainer>
      <ButtonContainer>
        <Button onClick={onClick}>{getProgramBtnTitle}</Button>
      </ButtonContainer>
    </Container>
  );
};

export default SliderBenefits;
