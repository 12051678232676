import React, { FC } from 'react';
import styled from 'styled-components';

import { DynamicImage, Text } from 'components';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';
import { mobile, useQuery } from 'styles/breakpoints';
import CircleCheckmark from 'assets/icons/circle-checkmark.svg';
import CircleCross from 'assets/icons/circle-cross.svg';
import StartNowButton from './StartNowButton';

interface AnswerBasedStaticProps {
  title: any;

  overline: string;
  summary: string;
  onClick: () => void;
  img: string;
  imgMobile: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 1.5rem;
  background-color: #fbfbff;
  @media ${mobile} {
    padding: 0;
    padding: 2rem 0rem;
  }
`;

const StyledTitle = styled(Text)`
  max-width: 48rem;
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 1rem;
  font-weight: 700;
  @media ${mobile} {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    max-width: 21.4375rem;
  }
`;

const StyledOverline = styled(Text)`
  font-size: 1rem;
  margin-bottom: 1rem;
  @media ${mobile} {
    font-size: 0.75rem;
    margin-bottom: 0.5rem;
  }
`;

const Summary = styled(Text)`
  font-size: 1rem;
  margin-bottom: 0.75rem;
  @media ${mobile} {
    font-size: 0.625rem;
    margin-bottom: 0rem;
  }
`;

const Button = styled(StartNowButton)``;

const Image = styled(DynamicImage)`
  max-width: 26.6875rem;
  width: 100%;
  @media ${mobile} {
    max-width: none;
  }
`;

const AnswerBasedStatic: FC<AnswerBasedStaticProps> = ({
  title,
  overline,
  summary,
  onClick,
  img,
  imgMobile,
}) => {
  const { isMobile } = useQuery();

  return (
    <Container>
      <StyledOverline>{overline}</StyledOverline>
      <StyledTitle
        type={isMobile ? 'countS' : 'count'}
        dangerouslySetInnerHTML={{ __html: title }}
      />
      <Summary>{summary}</Summary>
      <Image src={isMobile ? imgMobile : img} />
      <Button onClick={onClick}>Get my plan</Button>
    </Container>
  );
};

export default AnswerBasedStatic;
