import React, { FC } from 'react';
import styled from 'styled-components';
import { DynamicImage, Text } from 'components';
import StartNowButton from './StartNowButton';
import { mobile, useQuery } from 'styles/breakpoints';

interface TimelineProps {
  title: string;
  label: string;
  image: string;
  imageMobile: string;
  onClick?: () => void;
  imageHasMargin?: boolean;
  bgColor?: string;
  removePadding?: boolean;
  maxWidth?: string;
  hideCta?: boolean;
}

const Container = styled.div<{ bgColor: string; removePadding: boolean }>`
  background-color: ${({ bgColor }) => bgColor};
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 4rem 0;
  @media ${mobile} {
    padding: ${({ removePadding }) => (removePadding ? '1rem 0' : '2rem 1rem')};
  }
`;

const InnerWrapper = styled.div`
  max-width: 71.25rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Title = styled(Text)<{ removePadding: boolean }>`
  font-size: 1.75rem;
  font-weight: 700;
  text-align: center;
  max-width: 31.25rem;
  margin: ${({ removePadding }) =>
    removePadding ? '1rem 1rem 2rem 1rem' : '0'};
  @media ${mobile} {
    font-size: 1.5rem;
  }
`;

const Button = styled(StartNowButton)``;

const Image = styled(DynamicImage)<{
  imageHasMargin: boolean;
  maxWidth: string;
}>`
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth};
  margin: ${({ imageHasMargin }) => (imageHasMargin ? '3rem 0' : '1.5rem 0')};
  @media ${mobile} {
    width: 100%;
    margin: ${({ imageHasMargin }) =>
      imageHasMargin ? '1.5rem 0 3rem 0' : '0'};
  }
`;

const Label = styled(Text)`
  color: #4d4d4d;
  font-size: 0.75rem;
  text-align: center;
  margin-top: 0.5rem;
`;

const Timeline: FC<TimelineProps> = ({
  title,
  label,
  image,
  imageMobile,
  onClick,
  imageHasMargin = false,
  bgColor = '#FBFBFF',
  removePadding = false,
  maxWidth = '31.25rem',
  hideCta,
}) => {
  const { isMobile } = useQuery();
  return (
    <Container bgColor={bgColor} removePadding={removePadding}>
      <InnerWrapper>
        <Title removePadding={removePadding}>{title}</Title>
        {label ? <Label>{label}</Label> : null}
        <Image
          src={isMobile ? imageMobile : image}
          imageHasMargin={imageHasMargin}
          maxWidth={maxWidth}
        />
        {hideCta ? null : <Button onClick={onClick}>Get my plan</Button>}
      </InnerWrapper>
    </Container>
  );
};

export default Timeline;
