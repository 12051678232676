import { DynamicImage, Text } from 'components';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';
import styled from 'styled-components';
import { tablet, useQuery } from 'styles/breakpoints';
import CheckPng from 'assets/icons/check_mark.png';

interface IconChecklistProps {
  image: string;
  title: string;
  icon: string;
  listItems: {
    icon: string;
    text: string;
    textAfterName?: string;
  }[];
}

const IconChecklist: FC<IconChecklistProps> = ({
  image,
  icon,
  title,
  listItems,
}) => {
  const { isTablet } = useQuery();

  const quizAnswers = useSelector((state: AppState) => state.user.quiz_answers);

  const seperationAnxiety =
    quizAnswers?.does_suffer_from_seperation_anxiety === 'yes'
      ? ['Overcome separation anxiety']
      : [];
  const socializationAnswer =
    quizAnswers?.importance_of_dogs_socialization === 'extremely_important' ||
    quizAnswers?.importance_of_dogs_socialization === 'important'
      ? ["Boost your dog's social skills in no time"]
      : [];

  const quizAnsersKeys = quizAnswers?.which_issues_solve?.split('|');

  const listArray = quizAnsersKeys?.map(answer => {
    switch (answer) {
      case 'pulling_leash':
        return 'Overcome leash pulling';
      case 'excessive_energy':
        return 'Deal with excessive energy';
      case 'excessive_barking':
        return 'Take control of excessive barking';
      case 'housebreaking_marking_issues':
        return 'Achieve perfect house training';
      case 'dog_aggression':
        return 'Reduce aggression';
      case 'ignoring_commands':
        return `Significantly improve ${quizAnswers.userDogName}'s obedience`;
      case 'destructive_behavior':
        return `Eliminate destructive behavior`;
      default:
        return null;
    }
  });

  return (
    <>
      <Container>
        <Card>
          <CardIcon alt="icon" src={image} />
          <CardTitle
            type={isTablet ? 'body800' : 'h3800'}
            textAlign="center"
            color="dark100"
          >
            {title}
          </CardTitle>

          <CheckListContainer>
            {[
              `Improve ${quizAnswers?.userDogName}'s happiness`,
              ...listArray,
              ...seperationAnxiety,
              ...socializationAnswer,
            ]?.map((item, index) => (
              <Item key={index}>
                <Icon alt="check" src={icon} />
                <StyledText color="dark100" type={isTablet ? 'bodyM' : 'body'}>
                  {item}
                </StyledText>
              </Item>
            ))}
          </CheckListContainer>
        </Card>
      </Container>
    </>
  );
};

export default IconChecklist;

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;

  @media ${tablet} {
    padding: 0 16px;
  }
`;
const Card = styled.div`
  width: 560px;
  background: #f6f4ef;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
  @media ${tablet} {
    width: 100%;
    padding: 24px;
  }
`;

const CardIcon = styled(DynamicImage)`
  width: 188px;
  height: 170.28px;
`;
const CardTitle = styled(Text)`
  width: 322px;
  margin: 16px 0;
  @media ${tablet} {
    width: 295px;
  }
`;

const CheckListContainer = styled.div`
  width: 100%;
  @media ${tablet} {
  }
`;

const Item = styled.div`
  display: flex;
  padding-bottom: 10px;
  align-items: center;
`;

const Icon = styled(DynamicImage)`
  height: 26px;
  width: 26px;
`;

const StyledText = styled(Text)`
  margin-left: 8px;
  @media ${tablet} {
  }
`;
