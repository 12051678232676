import React, { FC } from 'react';
import styled from 'styled-components';
import { Text } from 'components';
import StartNowButton from './StartNowButton';
import { mobile, tablet } from 'styles/breakpoints';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';

interface BookInsightsProps {
  title: string;
  cards: {
    title: string;
    subTitle: string;
  }[];
  onClick: () => void;
}

const BookInsights: FC<BookInsightsProps> = ({ title, cards, onClick }) => {
  const quizAnswers = useSelector((state: AppState) => state.user.quiz_answers);

  return (
    <>
      <Container>
        <Title>{title}</Title>
        <CardContainer>
          {cards.map((item, index) => (
            <Card key={index}>
              <CardTitle>{item.title}</CardTitle>
              <Text type="body" color="dark100" textAlign="center">
                {item.subTitle}
              </Text>
            </Card>
          ))}
        </CardContainer>
        <Button onClick={onClick}>Get your book</Button>
      </Container>
    </>
  );
};

export default BookInsights;

const Button = styled(StartNowButton)`
  width: 560px;
  height: 56px;
  @media ${tablet} {
    width: 100%;
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  @media ${tablet} {
    flex-wrap: no-wrap;
    flex-direction: column;
    width: 100%;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  width: 272px;
  height: 140px;
  background: #fff9ef;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 1rem;
  @media ${tablet} {
    width: 100%;
    height: auto;
  }
`;

const CardTitle = styled.p`
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 900;
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  color: #38724f;
`;

const Title = styled(Text)`
  font-weight: 700;
  font-size: 1.75rem;
  line-height: 130%;
  letter-spacing: -0.036em;
  text-align: center;

  @media ${tablet} {
    font-size: 1.5rem;
    width: 230px;
  }
  @media ${mobile} {
    font-size: 1.25rem;
  }
`;

const Container = styled.div`
  width: 576px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  gap: 1.5rem;
  @media ${tablet} {
    width: unset;
  }
`;
