import React, { FC } from 'react';
import styled from 'styled-components';
import { mobile, useQuery } from 'styles/breakpoints';

import { DynamicImage, Text } from 'components';

interface StatisticsItems {
  text: string;
  image?: string;
  header?: string;
}

interface StatisticsProps {
  bottomItems: StatisticsItems[];
  title: string;
}

const Container = styled.div`
  background-color: #f6f3ff;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 4rem 0;
  @media ${mobile} {
    padding: 2rem 1rem;
  }
`;

const InnerWrapper = styled.div`
  max-width: 71.25rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StatisticsCard = styled.div`
  background-color: #fff;
  display: flex;
  border-radius: 16px;
  width: 100%;
  justify-content: space-around;

  padding: 1rem;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.06);
  max-width: 468px;

  @media ${mobile} {
    flex-direction: column;
  }
`;

const StatisticsItemDesktop = styled.div<{ marginRight?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: flex-start;
  background: #ffffff;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
  padding: 1rem;
  margin-right: ${({ marginRight }) => (marginRight ? '1.5rem' : '0')};
`;

const StatisticsItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: flex-start;
  @media ${mobile} {
    flex-direction: row;
  }
`;
const StatisticsHeader = styled(Text)`
  font-weight: 700;
  color: #21155e;
  font-size: 1.75rem;
  @media ${mobile} {
    margin-bottom: unset;
    font-size: 1.25rem;
  }
`;
const StatisticsImage = styled(DynamicImage)`
  width: 4rem;
  height: 4rem;

  @media ${mobile} {
    width: 3rem;
    height: 3rem;
  }
`;

const StatisticsText = styled(Text)`
  max-width: 130px;
  text-align: center;
  @media ${mobile} {
    /* max-width: 91px; */
    max-width: none;
    margin-left: 0.75rem;
    text-align: left;
    flex: 1;
  }
`;

const Title = styled(Text)`
  font-size: 1.75rem;
  font-weight: 700;
  margin-bottom: 3rem;
  @media ${mobile} {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }
`;

const IconContainer = styled.div`
  height: 4rem;
  width: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.75rem;
  @media ${mobile} {
    margin-bottom: 0;
    height: 3rem;
    width: 3rem;
  }
`;

const StatisticsContainerDesktop = styled.div`
  display: flex;
  flex-direction: row;
`;

const Statistics: FC<StatisticsProps> = ({ bottomItems, title }) => {
  const { isMobile } = useQuery();

  const renderStatisticsItems = (bottomItems: StatisticsItems[]) =>
    bottomItems.map((item, indx) => (
      <StatisticsItem key={indx}>
        <IconContainer>
          {item.header ? (
            <StatisticsHeader fontSize={1.5}>{item.header}</StatisticsHeader>
          ) : (
            <StatisticsImage alt={''} src={item.image || ''} />
          )}
        </IconContainer>
        <StatisticsText fontSize={0.75}>{item.text}</StatisticsText>
      </StatisticsItem>
    ));

  const renderStatisricsItemsDesktop = (bottomItems: StatisticsItems[]) =>
    bottomItems.map((item, indx) => (
      <StatisticsItemDesktop
        key={indx}
        marginRight={indx !== bottomItems.length - 1}
      >
        <IconContainer>
          {item.header ? (
            <StatisticsHeader fontSize={2.25}>{item.header}</StatisticsHeader>
          ) : (
            <StatisticsImage alt={''} src={item.image || ''} />
          )}
        </IconContainer>
        <StatisticsText fontSize={0.75}>{item.text}</StatisticsText>
      </StatisticsItemDesktop>
    ));

  return (
    <Container>
      <InnerWrapper>
        <Title>{title}</Title>
        {isMobile ? (
          <StatisticsCard>{renderStatisticsItems(bottomItems)}</StatisticsCard>
        ) : (
          <StatisticsContainerDesktop>
            {renderStatisricsItemsDesktop(bottomItems)}
          </StatisticsContainerDesktop>
        )}
      </InnerWrapper>
    </Container>
  );
};

export default Statistics;
