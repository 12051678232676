import React, { FC, useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { Headline, LoadingPage, PrimaryButton, Reviews, Seo } from 'components';
import { tablet, useQuery } from 'styles/breakpoints';
import { useRouter } from 'apis/history';
import SeparatorIcon from 'assets/icons/separator.svg';
import { useFirstVisitDate, usePageView, useQuizData } from 'utils/hooks';
import { AppState } from 'state/types';

import AnswerResults from './components/AnswerResults';
import Help from './components/Help';
import MembersStatistics from './components/MembersStatistics';
import Description from './components/Description';
import CheckItem from 'components/CheckItem';
import IconList from './components/IconList';
import HorizontalList from './components/HorizontalList';
import AppActions from './components/AppActions';
import AppFeatures from './components/AppFeatures';
import CaseStudies from './components/CaseStudies';
import BannerSection from 'components/BannerSection';
import SliderBenefits from './components/SliderBenefits';
import CheckoutDescription from 'pages/start/checkout/components/Description';
import 'swiper/css';
import Consultation from './components/Consultation';
import SliderGraph from './components/SliderGraph';
import Container from 'pages/start/checkout/components/Container';
import ImageSection from 'pages/start/checkout/components/ImageSection';
import 'swiper/css/pagination';
import AnswerImages from './components/AnswerImages';
import GraphAndType from './components/GraphAndType';
import AdhdIntro from './components/AdhdIntro';
import StartButton from './components/StartNowButton';
import AnswerBasedImages from './components/AnswerBasedImages';
import AnswerBased from './components/AnswerBased';
import Statistics from './components/Statistics';
import Timeline from './components/Timeline';
import AnswerBasedStatic from './components/AnswerBasedStatic';
import BookInsights from './components/BookInsights';
import TextImageCardButton from './components/TextImageCardButton';
import PersonalSummary from './components/PersonalSummary';
import IconChecklist from './components/IconChecklist';
import PersonalizedRoad from './components/PersonalizedRoad';
import SuccessfullyImprovedBadge from './components/SuccessfullyImprovedBadge';
import theme from 'utils/theme';
import GoalsStats from './components/GoalsStats';
import OurPromise from './components/OurPromise';
import Layout from 'layouts/Layout';
import BlackFridayBannerCheckout from 'components/BlackFridayBannerCheckout';
import BlackFridayCountdown from 'components/BlackFridayCountdown';
import { ResultsFooter } from './components/ResultsFooter';
import { BenefitsPublication } from './components/BenefitsPublication';
import TitleTextImage from './components/TitleTextImage';
import { WithWithout } from './components/WithWithout';
import { CandleGraph } from './components/CandleGraph';
import { Percentage } from './components/Percentage';
import WeightLossSummary from 'components/WeightLossSummary';
import WeightLossChartComponent from './components/WeightLossChartComponent';
import { Quiz } from 'types/quiz';
import Tracking from 'utils/tracking';
import { normalizeStates } from 'utils/localization';

const Button = styled(PrimaryButton).attrs({
  cornerVariant: 'round',
})`
  background: ${({ theme }) => theme.colors.orange};
  padding: 0.625rem 1.5rem;
  min-width: 10.22rem;
  max-width: 15rem;
  height: 48px;
  @media ${tablet} {
    height: 36px;
    min-width: 7.06rem;
  }
`;

const Separator = styled(SeparatorIcon)`
  width: 100vw;
`;

const EvenList = styled.div`
  display: flex;
  justify-content: space-evenly;

  @media ${tablet} {
    flex-direction: column;

    div:nth-child(1n):not(last-child) {
      margin-bottom: 1.66rem;
    }
  }
`;

const StartNow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem 0;
`;

const ContentWrapper = styled.div<{ $padding?: string; $bgColor?: string }>`
  background-color: ${({ $bgColor, theme }) => $bgColor || theme.colors.light0};
  padding: ${({ $padding }) => $padding || 0};
`;
const CenterContent = styled.div<{
  $width?: string;
  $maxWidth: string;
  $padding?: string;
}>`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: ${({ $width }) => $width || 'auto'};
  max-width: ${({ $maxWidth }) => $maxWidth || 'auto'};
  padding: ${({ $padding }) => $padding || 0};
`;

const Results: FC<RouteComponentProps> = ({ location }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const bottomRef = useRef<HTMLDivElement>(null);
  const config = useSelector((state: AppState) => state.config);
  const state = useSelector((state: AppState) => state);
  const quiz = new URLSearchParams(location?.search).get('qz') ?? Quiz.Main;
  const user = useSelector((state: AppState) => state.user);

  const variant = config?.variant;
  const { countdownDiffInMS } = useFirstVisitDate();

  const quizData = useQuizData('results');

  const { goToEmail, goToCheckout } = useRouter();

  const { isTablet } = useQuery();

  usePageView({
    client_code: user.code,
    city: encodeURIComponent(
      user.geolocation?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
    ),
    country: user.geolocation?.iso_country?.toLowerCase(),
    state: normalizeStates(
      user.geolocation?.iso_country || '',
      user.geolocation?.iso_state || '',
    ),
    gender: user.quiz_answers?.gender ? user.quiz_answers?.gender[0] : null,
    email: user.user?.email,
  });

  const handleContinue = () => {
    setIsLoading(true);
    if (location?.pathname) {
      Tracking.trackCTAButton(location.pathname);
    }
    if (variant === 'email-first') {
      return goToCheckout({ discount: false });
    }
    goToEmail();
    setIsLoading(false);
  };

  const renderSection = (item: any, index: number) => {
    const key = `${item.type}-${index}`;

    switch (item.type) {
      default: {
        return <React.Fragment key={key} />;
      }
      case 'basedOnMultiple': {
        return (
          <React.Fragment key={key}>
            <AnswerImages item={item} onClick={handleContinue} />
          </React.Fragment>
        );
      }
      case 'answerBased': {
        return (
          <React.Fragment key={key}>
            <AnswerBased {...item} onClick={handleContinue} />
          </React.Fragment>
        );
      }
      case 'section1': {
        return (
          <React.Fragment key={key}>
            <AnswerResults onClick={handleContinue} {...item} />
            {!isTablet && <Separator />}
          </React.Fragment>
        );
      }
      case 'caseStudiesSection': {
        return (
          <CaseStudies key={key} onStartBtnClick={handleContinue} {...item} />
        );
      }
      case 'appFeaturesSection': {
        return <AppFeatures key={key} {...item} />;
      }
      case 'section2': {
        return (
          <React.Fragment key={key}>
            <MembersStatistics {...item} />
            <Separator />
          </React.Fragment>
        );
      }
      case 'consultation': {
        return <Consultation item={item} onClick={handleContinue} />;
      }
      case 'section3': {
        return (
          <React.Fragment key={key}>
            <Description
              title={item.title}
              onClick={handleContinue}
              tabletSmTitle
              startBtnTitle={item?.startBtnTitle}
            >
              <EvenList>
                {item.list?.map((item: string, index: number) => (
                  <CheckItem key={`description-1-${index}`}>{item}</CheckItem>
                ))}
              </EvenList>
            </Description>
            <Separator />
          </React.Fragment>
        );
      }
      case 'section4': {
        return (
          <Description key={key} title={item.title} subtitle={item.subtitle}>
            <IconList data={item.list} />
          </Description>
        );
      }
      case 'section5': {
        return <AppActions key={key} {...item} />;
      }
      case 'section6': {
        return (
          <Description
            key={key}
            title={item.title}
            subtitle={item.subtitle}
            tabletCenterTitle
            onClick={handleContinue}
            startBtnTitle={item?.startBtnTitle}
          >
            <HorizontalList data={item.list} />
          </Description>
        );
      }
      case 'section7': {
        return <Help key={key} title={item.title} list={item.list} />;
      }
      // case 'section8': {
      //   return <Promise key={key} {...item} onClick={handleContinue} />;
      // }
      case 'scientificEvidenceSection': {
        return <BannerSection key={key} {...item} />;
      }
      case 'sliderBenefits': {
        return <SliderBenefits key={key} {...item} onClick={handleContinue} />;
      }
      case 'sliderGraph': {
        return <SliderGraph key={key} {...item} />;
      }
      case 'appBenefitsSection': {
        return (
          <Container key={key} top={4} bottom={4}>
            <CheckoutDescription {...item} onClick={handleContinue} />
          </Container>
        );
      }
      case 'reviewsSection': {
        return <Reviews {...item} key={key} onClick={handleContinue} />;
      }
      case 'imageSection': {
        return <ImageSection key={key} {...item} />;
      }
      case 'graphAndTypeSection': {
        return <GraphAndType key={key} {...item} onClick={handleContinue} />;
      }
      case 'adhdIntro': {
        return <AdhdIntro key={key} {...item} onClick={handleContinue} />;
      }
      case 'answerBasedImages': {
        return (
          <AnswerBasedImages key={key} {...item} onClick={handleContinue} />
        );
      }
      case 'answerBasedStatic': {
        return (
          <AnswerBasedStatic key={key} {...item} onClick={handleContinue} />
        );
      }
      case 'statistics': {
        return <Statistics key={key} {...item} onClick={handleContinue} />;
      }
      case 'timeline': {
        return <Timeline key={key} {...item} onClick={handleContinue} />;
      }
      case 'bookInsights': {
        return (
          <ContentWrapper
            key={key}
            $padding={isTablet ? '3rem 1rem' : '4rem 1rem 3rem 1rem'}
          >
            <BookInsights key={key} {...item} onClick={handleContinue} />
          </ContentWrapper>
        );
      }
      case 'text_image_card_button': {
        return (
          <TextImageCardButton key={key} {...item} onClick={handleContinue} />
        );
      }
      case 'personal_summary': {
        return <PersonalSummary key={key} {...item} />;
      }
      case 'icon_checklist': {
        return <IconChecklist key={key} {...item} onClick={handleContinue} />;
      }
      case 'book_review': {
        return (
          <ContentWrapper
            key={key}
            $padding={isTablet ? '36px 1rem' : '4rem 1rem'}
          >
            {/* <BookReview {...item} onClick={handleContinue} /> */}
          </ContentWrapper>
        );
      }
      case 'ourPromise': {
        return (
          <ContentWrapper
            key={key}
            $padding={isTablet ? '1.5rem 1rem' : '3rem 1rem 4rem 1rem'}
            $bgColor="#436460"
          >
            <OurPromise key={key} {...item} onClick={handleContinue} />

            <CenterContent
              $width={isTablet ? '100%' : '560px'}
              $maxWidth="560px"
              $padding={isTablet ? '1.5rem 0 0 0' : '1.5rem 0 0 0'}
            >
              <StartButton
                onClick={handleContinue}
                color={'dark100'}
                loading={isLoading}
              >
                {item?.startBtnTitle || 'Get Nova now'}
              </StartButton>
            </CenterContent>
          </ContentWrapper>
        );
      }
      case 'personalized_road': {
        return (
          <ContentWrapper
            key={key}
            $bgColor={theme.colors.lightBg}
            $padding={
              isTablet ? '2.25rem 1rem 1.5rem 1rem' : '4rem 1rem 1.5rem 1rem'
            }
          >
            <PersonalizedRoad {...item} />
          </ContentWrapper>
        );
      }
      case 'goals_stats': {
        return (
          <ContentWrapper
            key={key}
            $bgColor={theme.colors.lightBg}
            $padding={isTablet ? '0 1rem 1.5rem 1rem' : '0 1rem 1.5rem 1rem'}
          >
            <GoalsStats {...item} />
          </ContentWrapper>
        );
      }
      case 'people_successfully_improved': {
        return (
          <ContentWrapper
            key={key}
            $bgColor={theme.colors.lightBg}
            $padding={isTablet ? '0 1rem 2.25rem 1rem' : '0 1rem 4rem 1rem'}
          >
            <SuccessfullyImprovedBadge {...item} />
            <CenterContent
              $width={isTablet ? '100%' : '560px'}
              $maxWidth="560px"
              $padding={isTablet ? '1.5rem 0 0 0' : '1.5rem 0 0 0'}
            >
              <StartButton onClick={handleContinue} />
            </CenterContent>
          </ContentWrapper>
        );
      }
      case 'bfBannerCheckout': {
        return <BlackFridayBannerCheckout key={key} {...item} />;
      }
      case 'bfCountdown': {
        return (
          <BlackFridayCountdown
            key={key}
            {...item}
            countdownDiffInMS={countdownDiffInMS}
            onClick={handleContinue}
          />
        );
      }
      case 'results_footer': {
        return <ResultsFooter key={key} {...item} />;
      }
      case 'benefits_publication': {
        return <BenefitsPublication key={key} {...item} />;
      }
      case 'title_text_image': {
        return (
          <ContentWrapper key={key} $bgColor={theme.colors.green120}>
            <TitleTextImage isMain={quiz === Quiz.Main} {...item} />;
          </ContentWrapper>
        );
      }
      case 'with_without': {
        return (
          <ContentWrapper key={key} $bgColor={theme.colors.green120}>
            <WithWithout {...item} />
            <CenterContent
              $width={isTablet ? '100%' : '560px'}
              $maxWidth="560px"
              $padding={'1.5rem 1rem 1.5rem 1rem'}
            >
              <StartButton
                onClick={handleContinue}
                color={'dark100'}
                loading={isLoading}
              >
                {item?.startBtnTitle || 'Get Nova now'}
              </StartButton>
            </CenterContent>
          </ContentWrapper>
        );
      }
      case 'candle_graph': {
        return (
          <ContentWrapper key={key} $bgColor={theme.colors.novaPink}>
            <CenterContent
              $width={isTablet ? '100%' : '560px'}
              $maxWidth="560px"
              $padding={
                isTablet ? '0 1rem 1.5rem 1rem' : '0rem 1rem 1.5rem 1rem'
              }
            >
              <CandleGraph {...item} />
              <StartButton
                onClick={handleContinue}
                color={'dark100'}
                loading={isLoading}
              >
                {item?.startBtnTitle || 'Get Nova now'}
              </StartButton>
            </CenterContent>
          </ContentWrapper>
        );
      }
      case 'percentage': {
        return (
          <ContentWrapper key={key} $bgColor={theme.colors.novaPink}>
            <CenterContent
              $width={isTablet ? '100%' : '560px'}
              $maxWidth="560px"
              $padding={
                isTablet ? '0.5rem 1rem 0.5rem' : '1.25rem 1rem 1.25rem 1rem'
              }
            >
              <Percentage {...item} />
            </CenterContent>
          </ContentWrapper>
        );
      }
      case 'weight-loss-summary': {
        return (
          <ContentWrapper key={key} $bgColor={theme.colors.green120}>
            <WeightLossSummary
              isMain={quiz === Quiz.Main}
              key={key}
              onClick={handleContinue}
              {...item}
            />
          </ContentWrapper>
        );
      }
      case 'weight-loss-chart': {
        return (
          <ContentWrapper key={key} $bgColor={theme.colors.novaPink}>
            <WeightLossChartComponent isMain={quiz === Quiz.Main} {...item} />
          </ContentWrapper>
        );
      }
    }
  };

  if (!quizData) {
    return null;
  }

  return (
    <Layout
      logoVariant={'center'}
      rightComponent={null}
      bgColor={'novaPink'}
      hideFooter
      bannerText={quizData?.bannerTopText}
      isStickyHeader={quizData?.bannerTopText ? false : true}
    >
      {/* <ContentLoader imagesToWait={searchForStringInRND(quizData.sections)} /> */}
      {!!quizData?.headlineTitle && (
        <Headline
          bodyText={quizData.headlineTitle}
          bgColor={theme.colors.novaPink}
        />
      )}

      {quizData?.sections?.map(renderSection)}
      {/* <StartNow ref={bottomRef}>
        <StartNowButton onClick={handleContinue}>
          {
            quizData?.sections?.find(({ type }) => type === 'section8')
              ?.startBtnTitle
          }
        </StartNowButton>
      </StartNow> */}
    </Layout>
  );
};

export default Results;
