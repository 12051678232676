import { Text } from 'components';
import BMI from 'components/BMI';
import MetabolicAge from 'components/MetabolicAge';
import WeightLossStats from 'components/WeightLossStats';
import React, { FC } from 'react';
import styled from 'styled-components';
import { tablet } from 'styles/breakpoints';

interface WeightLossSummaryProps {
  title: string;
  isMain: boolean;
  onClick: () => void;
}

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const InnerContainer = styled.div`
  width: 100%;
  max-width: 560px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1rem;
  @media ${tablet} {
  }
`;

const Title = styled(Text)`
  color: #fff;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: center;
  margin: 3rem 0 1.5rem 0;
  font-size: 28px;
  line-height: 39px;

  @media ${tablet} {
    margin: 1.5rem 0;

    font-size: 24px;
    line-height: 34px;
  }
`;

const MetabolicAgeStyled = styled(MetabolicAge)<{ isMain: boolean }>`
  margin-top: 1.5rem;
  margin-bottom: ${({ isMain }) => (isMain ? '1rem' : '0')};
  @media ${tablet} {
    margin-top: 1rem;
  }
`;

const BMIStyled = styled(BMI)`
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  @media ${tablet} {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`;

const WeightLossSummary: FC<WeightLossSummaryProps> = ({ title, isMain }) => (
  <Container>
    <InnerContainer>
      <Title>{title}</Title>
      <WeightLossStats />
      <MetabolicAgeStyled isMain={isMain} />
      {isMain ? null : <BMIStyled />}
    </InnerContainer>
  </Container>
);

export default WeightLossSummary;
