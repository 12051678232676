import React from 'react';

import Metabolic from 'assets/icons/metabolic.svg';
import styled from 'styled-components';
import Text from './Text';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';
import { tablet, useQuery } from 'styles/breakpoints';
import ErrorMessage from './ErrorMessage';
import { Quiz } from 'types/quiz';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const InnerContainer = styled.div`
  max-width: 580px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  box-shadow: 0px 4px 16px 0px #00000014;
  background-color: #ffffff;
  padding: 1.5rem;
  border-radius: 16px;
  @media ${tablet} {
  }
`;

const YourAgeContainer = styled.div`
  margin-left: 1rem;
  margin-bottom: 1rem;
`;

const Label = styled(Text)`
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  font-size: 20px;
  line-height: 28px;
  @media ${tablet} {
    font-size: 12px;
    line-height: 17px;
  }
`;

const Years = styled(Text)`
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
  color: #f47170;
  font-size: 32px;
  line-height: 45px;
  @media ${tablet} {
    font-size: 24px;
    line-height: 34px;
  }
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const MetabolicAge = ({ ...props }) => {
  const age = useSelector(
    (state: AppState) => state.user?.quiz_answers?.age || '',
  );
  const { isTablet } = useQuery();
  const quiz = new URLSearchParams(location?.search).get('qz') ?? Quiz.Main;

  return (
    <Container {...props}>
      <InnerContainer>
        <TopContainer>
          <Metabolic width={isTablet ? 48 : 64} />
          <YourAgeContainer>
            <Label>Your biological age</Label>
            <Years>{Number(age) + 12} years</Years>
          </YourAgeContainer>
        </TopContainer>
        <ErrorMessage
          errorText={
            quiz === Quiz.Main
              ? 'Your biological age is higher than it should be'
              : 'Your metabolism is slower than it should be'
          }
        />
      </InnerContainer>
    </Container>
  );
};

export default MetabolicAge;
