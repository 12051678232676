import React from 'react';

import styled from 'styled-components';
import Text from './Text';
import WeightSVG from 'assets/icons/current-weight.svg';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';

import OverweightSVG from 'assets/images/weight-loss/overweight.svg';
import UnderweightSVG from 'assets/images/weight-loss/underweight.svg';
import HealthySVG from 'assets/images/weight-loss/healthy.svg';
import { tablet, useQuery } from 'styles/breakpoints';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const InnerContainer = styled.div`
  max-width: 560px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 4px 16px 0px #00000014;
  padding: 1.5rem 2rem;
  border-radius: 16px;

  @media ${tablet} {
  }
`;

const Label = styled(Text)`
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  font-size: 20px;
  line-height: 28px;

  @media ${tablet} {
    font-size: 12px;
    line-height: 17px;
  }
`;

const Years = styled(Text)`
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;

  font-size: 32px;
  line-height: 45px;
  @media ${tablet} {
    font-size: 24px;
    line-height: 34px;
  }
`;
const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const YourAgeContainer = styled.div`
  margin-left: 1rem;
  margin-bottom: 1rem;
`;

const BMI = ({ ...props }) => {
  const quiz_answers = useSelector(
    (state: AppState) => state.user?.quiz_answers,
  );
  const currentWeight = quiz_answers?.current_weight || '';
  const currentWeightUnit = quiz_answers?.current_weight_unit || '';
  const { height_cm, height_ft, height_in, height_unit } = quiz_answers || {};
  const { isTablet } = useQuery();

  const calculateBMI = () => {
    const h = (function () {
      if (height_unit === 'metric') {
        return Number(height_cm) / 100;
      }
      const inches = Number(height_ft) * 12 + Number(height_in);
      return inches * 0.0254;
    })();
    const weightNumber = Number(currentWeight);
    let w = currentWeightUnit === 'lb' ? weightNumber / 2.205 : weightNumber;
    return w / (h * h);
  };

  const bmi = calculateBMI();

  const renderHint = () => {
    if (bmi < 18.5) {
      return <UnderweightSVG />;
    }
    if (bmi < 25) {
      return <HealthySVG />;
    }
    return <OverweightSVG />;
  };

  return (
    <Container {...props}>
      <InnerContainer>
        <TopContainer>
          <WeightSVG width={isTablet ? 48 : 64} />
          <YourAgeContainer>
            <Label>Current BMI</Label>
            <Years>{bmi.toFixed(2)}</Years>
          </YourAgeContainer>
        </TopContainer>
        {renderHint()}
      </InnerContainer>
    </Container>
  );
};

export default BMI;
