import React, { FC } from 'react';
import styled from 'styled-components';
import Button from './StartNowButton';

import { tablet, useQuery } from 'styles/breakpoints';
import { DynamicImage } from 'components';

const Container = styled.div`
  max-width: 71.25rem;
  padding: 4rem 1rem;

  @media ${tablet} {
    padding: 3rem 1rem;
  }

  margin: auto;
`;

const StyledButton = styled(Button)`
  margin: auto;
  margin-top: 1.5rem;

  @media ${tablet} {
    margin-top: 2.25rem;
  }
`;

interface Item {
  image: string;
  imageMobile: string;
  startBtnTitle: string;
}

interface IConsulation {
  item: Item;
  onClick: () => void;
}

const Consultation: FC<IConsulation> = ({ item, onClick }) => {
  const { isMobile } = useQuery();
  return (
    <Container>
      <DynamicImage
        src={item.image}
        mobile_src={item.imageMobile}
        alt="consultation"
      />

      <StyledButton onClick={onClick}>{item.startBtnTitle}</StyledButton>
    </Container>
  );
};

export default Consultation;
