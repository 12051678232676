import React, { FC } from 'react';
import styled from 'styled-components';

import { DynamicImage, Text } from 'components';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';
import { mobile, useQuery } from 'styles/breakpoints';
import CircleCheckmark from 'assets/icons/circle-checkmark.svg';
import CircleCross from 'assets/icons/circle-cross.svg';
import StartNowButton from './StartNowButton';

interface AnswerBasedProps {
  title?: string;
  titles: any;
  bottomItems: StatisticsItems[];
  resultCard: any[];
  overline: string;
  onClick: () => void;
  summary: string;
  oldVersion?: boolean;
}

interface AnswerUrl {
  [key: string]: string;
}

interface StatisticsItems {
  text: string;
  image?: string;
  header?: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 1.5rem;
  background-color: #fbfbff;
  @media ${mobile} {
    padding: 2rem 1rem;
  }
`;

const ResultsCard = styled.div`
  background-color: #fff;
  display: flex;
  border-radius: 16px;
  width: 100%;
  justify-content: space-around;
  min-height: 6.75rem;
  padding: 1rem;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.06);
  flex-direction: column;
  max-width: 468px;
`;

const ResultWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
`;

const ResultImage = styled(DynamicImage)`
  padding-right: 0.5rem;
  height: 36px;
  width: 36px;
  @media ${mobile} {
    height: 24px;
    width: 24px;
  }
`;

const ResultTitle = styled(Text)`
  font-size: 1.125rem;
  font-weight: 500;
  margin-left: 0.5rem;
  @media ${mobile} {
    font-weight: 700;

    font-size: 0.75rem;
  }
`;

const OnTrack = styled.div`
  background-color: rgb(47, 190, 143);
  height: 29px;
  display: flex;
  width: 108px;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  color: #fff;
  font-weight: 700;
  font-size: 0.75rem;
  height: 32px;
  width: 139px;
  @media ${mobile} {
    font-size: 0.625rem;
    height: 29px;
    width: 108px;
  }
`;

const BeBetter = styled.div`
  background-color: #2fbe8f;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  color: #fff;
  font-weight: 700;
  font-size: 0.75rem;
  height: 32px;
  width: 139px;
  @media ${mobile} {
    font-size: 0.625rem;
    height: 29px;
    width: 108px;
  }
`;

const OnTrackNew = styled(Text)`
  font-size: 0.875rem;
  font-weight: 700;
  color: #2fbe8f;
  margin-right: 0.25rem;
`;

const BeBetterNew = styled(OnTrackNew)`
  color: #21155e;
`;

const ResultItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const WrapperContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledTitle = styled(Text)`
  max-width: 48rem;
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 3rem;
  font-weight: 700;
  @media ${mobile} {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }
`;

const StyledOverline = styled(Text)`
  font-size: 1rem;
  margin-bottom: 1rem;
  @media ${mobile} {
    font-size: 0.75rem;
    margin-bottom: 0.5rem;
  }
`;

const Summary = styled(Text)`
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
  text-align: center;
  @media ${mobile} {
  }
`;

const Button = styled(StartNowButton)`
  margin-top: 2.25rem;
`;

const AnswerBased: FC<AnswerBasedProps> = ({
  title,
  titles,
  overline,
  resultCard,
  onClick,
  summary,
  oldVersion = false,
}) => {
  const { isMobile } = useQuery();
  const { quiz_answers } = useSelector((state: AppState) => state.user);

  const titleAnswer = quiz_answers['life' as keyof typeof quiz_answers];

  const renderResults = (resultCard: any) =>
    resultCard.map((item, indx) => (
      <ResultWrapper key={indx}>
        <WrapperContainer>
          <ResultImage alt="" src={item.icon} />
          <ResultTitle>{item.name}</ResultTitle>
        </WrapperContainer>
        {quiz_answers[item.key as keyof typeof quiz_answers] ===
        item.onTrackValue
          ? renderOnTrack()
          : renderBeBetter()}
      </ResultWrapper>
    ));

  const renderOnTrack = () =>
    oldVersion ? (
      <OnTrack>Right on track</OnTrack>
    ) : (
      <ResultItemContainer>
        <OnTrackNew>Right on track</OnTrackNew>
        <CircleCheckmark />
      </ResultItemContainer>
    );
  const renderBeBetter = () =>
    oldVersion ? (
      <BeBetter>Could be better</BeBetter>
    ) : (
      <ResultItemContainer>
        <BeBetterNew>Could be better</BeBetterNew>
        <CircleCross fill="#21155E" />
      </ResultItemContainer>
    );
  return (
    <Container>
      <StyledOverline>{overline}</StyledOverline>
      <StyledTitle
        type={isMobile ? 'countS' : 'count'}
        dangerouslySetInnerHTML={{ __html: title || titles[titleAnswer] }}
      />
      <ResultsCard>
        <Summary>{summary}</Summary>
        {renderResults(resultCard)}
      </ResultsCard>
      <Button onClick={onClick}>Get my plan</Button>
    </Container>
  );
};

export default AnswerBased;
