import React from 'react';
import styled from 'styled-components';
import { DynamicImage, Text } from 'components';
import theme from 'utils/theme';
import { smMobile, tablet } from 'styles/breakpoints';

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 40px;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  width: 560px;
  padding: 2rem;
  border-radius: 16px;
  background: ${({ theme }) => theme.colors.light0};
  box-shadow: ${({ theme }) => theme.shadows.cardShadow};
  @media ${tablet} {
    justify-content: space-between;
    padding: 1.5rem;
    max-width: 560px;
    width: 100%;
  }
`;

const SideBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  flex-grow: 1;
  gap: 1rem;
`;

const Divider = styled.div`
  width: 1px;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.light100};
  margin: 0 2rem;
  height: 255px;
  @media ${tablet} {
    margin: 0 0.5rem;
  }
`;

const StyledTitle = styled(Text)<{ $bgColor: string; $color?: string }>`
  display: flex;
  padding: 0.5rem 1.5rem;
  justify-content: center;
  align-items: center;
  background: ${({ $bgColor }) => $bgColor || 'transparent'};
  color: ${({ $color, theme }) => $color || theme.colors.light0};
  border-radius: 8px;
  width: fit-content;
  @media ${smMobile} {
    font-size: 0.625rem;
  }
`;

const StatInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;
const StatInfoContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const ResultsRowWrapper = styled.div`
  display: flex;
  flex: 1 1 1;
  flex-direction: row;
  gap: 6px;
`;
const ResultsRowItem = styled.div<{ $bgColor?: string; $isActive?: boolean }>`
  height: 6px;
  flex-grow: 1;
  border-radius: 2px;
  background: ${({ $bgColor, $isActive, theme }) =>
    $bgColor || ($isActive ? theme.colors.light40 : theme.colors.primary)};
`;

const Label = styled(Text)`
  @media ${smMobile} {
    font-size: 0.7rem;
  }
`;

const EvaluationLabel = styled(Text)`
  @media ${smMobile} {
    font-size: 0.625rem;
  }
`;

type GoalsStatsProps = {
  stats: {
    leftSide: {
      title: string;
      evaluations: GoalsStatItemProps[];
    };
    rightSide: {
      title: string;
      evaluations: GoalsStatItemProps[];
    };
  };
};
type GoalsStatItemProps = {
  label: string;
  evaluationLabel: string;
  level: number;
  lineBgColor: string;
  lineCheckedBgColor: string;
};

const StatInformation = ({
  label,
  evaluationLabel,
  lineBgColor,
  lineCheckedBgColor,
  level,
}: GoalsStatItemProps) => (
  <StatInfoContent>
    <Label type="bodyM700">{label}</Label>
    <EvaluationLabel type="bodyM500">{evaluationLabel}</EvaluationLabel>
    <ResultsRowWrapper>
      <ResultsRowItem
        $bgColor={level > 0 ? lineCheckedBgColor : lineBgColor}
        $isActive={level > 0}
      />
      <ResultsRowItem
        $bgColor={level > 1 ? lineCheckedBgColor : lineBgColor}
        $isActive={level > 1}
      />
      <ResultsRowItem
        $bgColor={level > 2 ? lineCheckedBgColor : lineBgColor}
        $isActive={level > 2}
      />
    </ResultsRowWrapper>
  </StatInfoContent>
);

const GoalsStats = ({ stats }: GoalsStatsProps) => (
  <Container>
    <Content>
      <SideBox>
        <StyledTitle type="bodyM700" $bgColor="#FFE1E1" $color="#FF4545">
          {stats.leftSide.title}
        </StyledTitle>
        <StatInfoContainer>
          {stats.leftSide.evaluations.map((statItem: GoalsStatItemProps) => (
            <StatInformation
              key={statItem.label}
              label={statItem.label}
              level={statItem.level}
              evaluationLabel={statItem.evaluationLabel}
              lineBgColor={statItem.lineBgColor}
              lineCheckedBgColor={statItem.lineCheckedBgColor}
            />
          ))}
        </StatInfoContainer>
      </SideBox>
      <Divider />
      <SideBox>
        <StyledTitle type="bodyM700" $bgColor={theme.colors.primary}>
          {stats.rightSide.title}
        </StyledTitle>
        <StatInfoContainer>
          {stats.rightSide.evaluations.map((statItem: GoalsStatItemProps) => (
            <StatInformation
              key={statItem.label}
              label={statItem.label}
              level={statItem.level}
              evaluationLabel={statItem.evaluationLabel}
              lineBgColor={statItem.lineBgColor}
              lineCheckedBgColor={statItem.lineCheckedBgColor}
            />
          ))}
        </StatInfoContainer>
      </SideBox>
    </Content>
  </Container>
);

export default GoalsStats;
