import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';
import styled from 'styled-components';
import { getOptionFromList } from 'utils/functions';
import { useQuizWeightResult } from 'utils/hooks';
import { Text } from 'components';
import { mobile, useQuery } from 'styles/breakpoints';
import Card from 'components/Card';
import StartNowButton from './StartNowButton';

interface AdhdIntroProps {
  title: string;
  label: string;
  imagesBasedOnAnswer: ImageBasedOnAnswer[];
  pictureAnwserKey: string;
  cardTitle: string;
  cardLabel: string;
  cardImgUrl: string;
  buttonTitle: string;
  onClick: () => void;
}

interface ImageBasedOnAnswer {
  imgUrl: string;
  whitelist: string[];
  blacklist: string[];
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled(Text)`
  margin-top: 3rem;
  margin-bottom: 0.75rem;
  text-align: center;
  @media ${mobile} {
    max-width: 21.4375rem;
  }
`;

const Label = styled(Text)`
  margin-bottom: 1.5rem;
  max-width: 32.625rem;
  text-align: center;
  @media ${mobile} {
    max-width: 21.4375rem;
  }
`;

const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  @media ${mobile} {
    flex-direction: column;
  }
`;

const Image = styled.img`
  width: 100%;
`;

const CardLeft = styled(Card)`
  margin: 0 3rem 0 0;
  @media ${mobile} {
    margin: 0 0 1.5rem 0;
  }
`;

const CardTitle = styled(Text)`
  margin-bottom: 0.75rem;
  margin-left: 0.375rem;
`;

const CardLabel = styled(Text)`
  margin-bottom: 1rem;
  margin-left: 0.375rem;
`;

const CardImg = styled.img`
  width: 100%;
`;

const Button = styled(StartNowButton)`
  margin: 3rem 0;
  @media ${mobile} {
    margin: 2.25rem 0;
  }
`;

const segmentScores = (score: number) => {
  if (score < 26) {
    return 'Mild';
  }
  if (score < 38) {
    return 'Moderate';
  }
  return 'Severe';
};

const AdhdIntro: FC<AdhdIntroProps> = ({
  title,
  label,
  imagesBasedOnAnswer,
  pictureAnwserKey,
  cardTitle,
  cardLabel,
  cardImgUrl,
  buttonTitle,
  onClick,
}) => {
  const { quiz_answers } = useSelector((state: AppState) => state.user);
  const { isMobile } = useQuery();

  const pictureAnswers: string[] =
    useMemo(
      () => quiz_answers[pictureAnwserKey]?.split('|'),
      [quiz_answers, pictureAnwserKey],
    ) || [];
  const option = getOptionFromList(
    imagesBasedOnAnswer,
    pictureAnswers,
  ) as ImageBasedOnAnswer;
  const { sum, total } = useQuizWeightResult(20, 52);

  return (
    <Container>
      <Title type={isMobile ? 'countS' : 'h13Rem'}>{title}</Title>
      <Label type={isMobile ? 'bodyM' : 'body'}>{label}</Label>
      <CardsContainer>
        <CardLeft>
          <Image src={option.imgUrl} />
        </CardLeft>
        <Card>
          <CardTitle type="h3">
            {`${cardTitle} ${sum}/${total} (${segmentScores(sum)})`}
          </CardTitle>
          <CardLabel type="bodyM" color="dark60">
            {cardLabel}
          </CardLabel>
          <CardImg src={cardImgUrl} />
        </Card>
      </CardsContainer>
      <Button onClick={onClick}>{buttonTitle}</Button>
    </Container>
  );
};

export default AdhdIntro;
