import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Text } from 'components';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

interface PercentageProps {
  text: string;
  percentNumber: number;
  durationInMS: number;
}

const Container = styled.div`
  border-radius: 0.5rem;
  border: 1px solid #eee;
  background: #fff;
  padding: 1rem 1.5rem;
`;

const Title = styled(Text)`
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
`;

// const ProgressBar = styled(CircularProgressbar)`
//   height: 5rem;
//   max-width: 5rem;
// `;

const ProgressText = styled.text`
  font-weight: 800 !important; /* Make sure to override the default font-weight */
  font-size: 1.5rem !important; /* Set the size of the text inside the progress bar */
  // You can add other styles here if needed
`;

const ProgressBar = styled(CircularProgressbar)`
  height: 5rem;
  max-width: 5rem;

  .CircularProgressbar-text {
    // Directly apply the styles here instead of using ${ProgressText}
    font-weight: 800 !important;
    font-size: 1.5rem !important;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const Percentage: FC<PercentageProps> = ({
  text,
  percentNumber,
  durationInMS,
}) => {
  const [percentage, setPercentage] = useState(0);

  const frameDuration = 1000 / 60;
  const totalFrames = Math.round(durationInMS / frameDuration);
  const percentageIncrement = percentNumber / totalFrames;

  useEffect(() => {
    let frame = 0;
    const interval = setInterval(() => {
      frame++;
      const updatePercentage = percentage + percentageIncrement;
      setPercentage(
        updatePercentage > percentNumber ? percentNumber : updatePercentage,
      );

      if (frame === totalFrames) {
        clearInterval(interval);
      }
    }, frameDuration);

    return () => clearInterval(interval);
  }, [percentage]);

  return (
    <Container>
      <Wrapper>
        <ProgressBar
          value={percentage}
          text={`${Math.round(percentage)}%`}
          strokeWidth={12}
          styles={buildStyles({
            pathColor: `#05A56F`,
            trailColor: 'rgba(47, 190, 143, 0.1)',
            textColor: '#05A56F',
            textSize: '20px',
            pathTransition: 'none',
            strokeLinecap: 'round',
          })}
        />
        <Title>{text}</Title>
      </Wrapper>
    </Container>
  );
};
