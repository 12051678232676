import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background: #fdfdfd;
  border: 1px solid #f7f9f8;
  box-shadow: 0px 5px 10px rgba(28, 28, 40, 0.08);
  border-radius: 16px;
  padding: 1rem;
  width: 21.4375rem;
`;

const Card = ({ ...props }) => <Container {...props} />;

export default Card;
