import { DynamicImage, Text } from 'components';
import React, { FC } from 'react';
import styled from 'styled-components';
import { mobile, sxMobile } from 'styles/breakpoints';

interface Objec {
  title: string;
  icon: string;
  items: string[];
}
interface WithWithoutProps {
  withNova: Objec;
  withoutNova: Objec;
}

const StyledIcon = styled(DynamicImage)`
  width: 2rem;
  height: 2rem;
  @media ${mobile} {
    width: 1.25006rem;
    height: 1.25006rem;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 2.45rem;
  @media ${mobile} {
    margin-bottom: 1.5rem;
  }
`;

const StyledTitle = styled(Text)`
  color: #fff;
  font-size: 1.5rem;
  @media ${mobile} {
    font-size: 1rem;
  }
`;

const StyledItem = styled(Text)`
  color: #fff;
  @media ${mobile} {
    font-size: 0.75rem;
  }
  @media ${sxMobile} {
    font-size: 0.625rem;
  }
`;

const CardSideLeft = styled.div`
  background-color: #ea7a74;
  padding: 2.25rem 1rem;
  border-radius: 0.81631rem 0rem 0rem 0.81631rem;
  width: 100%;
  max-width: 17.88263rem;
  @media ${mobile} {
    padding: 24px 0px 20px 8px;
  }
`;

const CardSideRight = styled.div`
  background-color: #05a56f;
  padding: 2.25rem 1rem 2.25rem 2rem;
  border-radius: 0rem 0.40819rem 0.40819rem 0rem;
  width: 100%;
  max-width: 17.88263rem;
  @media ${mobile} {
    padding: 24px 0px 20px 8px;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  @media ${mobile} {
    margin: 0 1rem;
  }
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.04rem;
  @media ${mobile} {
    gap: 1.25rem;
  }
`;

export const WithWithout: FC<WithWithoutProps> = ({
  withNova,
  withoutNova,
}) => (
  <Container>
    <CardSideLeft>
      <TitleContainer>
        <StyledIcon src={withoutNova.icon} alt="exclamation_mark" />
        <StyledTitle>{withoutNova.title}</StyledTitle>
      </TitleContainer>
      <ItemContainer>
        {withoutNova.items.map((item, indx) => (
          <StyledItem key={indx}>{item}</StyledItem>
        ))}
      </ItemContainer>
    </CardSideLeft>
    <CardSideRight>
      <TitleContainer>
        <StyledIcon src={withNova.icon} alt="check" />
        <StyledTitle>{withNova.title}</StyledTitle>
      </TitleContainer>
      <ItemContainer>
        {withNova.items.map((item, indx) => (
          <StyledItem key={indx}>{item}</StyledItem>
        ))}
      </ItemContainer>
    </CardSideRight>
  </Container>
);
