import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { DynamicImage, Text } from 'components';
import { useQuizAnswers } from 'utils/hooks';
import { tablet } from 'styles/breakpoints';
import Button from './StartNowButton';

interface Item {
  type: string;
  key: string;
  title: string;
  subtitle: string;
  startBtnTitle: string;
  options: [
    {
      value: string;
      image: string;
    },
  ];
}
interface AnswerImage {
  item: Item;
  onClick: () => void;
}

const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 1rem;
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  gap: 1.5rem;
  max-width: 68.75rem;
  margin-top: 3rem;

  @media ${tablet} {
    margin-top: 1.5rem;
  }
`;

const Title = styled(Text)`
  font-weight: 700;
  font-size: 3rem;
  line-height: 130%;
  text-align: center;
  max-width: 48rem;
  margin-top: 3rem;

  @media ${tablet} {
    font-size: 1.625rem;
    margin-top: 1.5rem;
    line-height: 2.1125rem;
  }
`;

const Subtitle = styled(Text)`
  font-weight: 500;
  font-size: 1.375rem;
  line-height: 1.925rem;
  text-align: center;
  max-width: 55.625rem;
  margin-top: 1rem;

  @media ${tablet} {
    font-size: 0.875rem;
    margin-top: 0.5rem;
    line-height: 1.225rem;
  }
`;

const StyledButton = styled(Button)`
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.5rem;

  @media ${tablet} {
    margin-top: 2.25rem;
  }
`;

const ColoredText = styled.p`
  display: inline;
  color: ${({ theme }) => theme.colors.green100};
  font-weight: bolder;
`;

const AnswerImages: FC<AnswerImage> = ({ item, onClick }) => {
  const { answers } = useQuizAnswers();

  const previousAnswer = answers[item.key as keyof typeof answers]
    .toString()
    .split('|');

  const renderImages = useMemo(() => {
    if (!previousAnswer) return null;
    const matchingQuestions = item.options.filter(option =>
      previousAnswer.includes(option.value),
    );
    if (!matchingQuestions.length) return null;
    return (
      <ImageWrapper>
        {matchingQuestions.map(matchingQuestion => (
          <DynamicImage
            key={matchingQuestion.value}
            src={matchingQuestion.image}
            alt={matchingQuestion.value}
            height="15.375rem"
            width="21.4375rem"
          />
        ))}
      </ImageWrapper>
    );
  }, [item]);

  return (
    <Content>
      <Title color="dark100">{item.title}</Title>
      <Subtitle>
        {item.subtitle}
        {previousAnswer.map((word, index) => {
          const cleanWord = word.replace('_', ' ');
          if (previousAnswer.length <= 1) {
            return <ColoredText key={word}>{cleanWord}</ColoredText>;
          }
          if (index === previousAnswer.length - 1) {
            return (
              <React.Fragment key={word}>
                {previousAnswer.length !== 2 && ','} and{' '}
                <ColoredText key={word}>{cleanWord}</ColoredText>
              </React.Fragment>
            );
          }
          if (index === previousAnswer.length - 2) {
            return <ColoredText key={word}>{cleanWord}</ColoredText>;
          }
          return <ColoredText key={word}>{cleanWord + ', '}</ColoredText>;
        })}
        .
      </Subtitle>
      {renderImages}
      <StyledButton onClick={onClick}>{item.startBtnTitle}</StyledButton>
    </Content>
  );
};

export default AnswerImages;
