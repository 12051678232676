import React, { FC } from 'react';
import styled from 'styled-components';
import { DynamicImage, Text } from 'components';
import { mobile } from 'styles/breakpoints';

interface CandleGraphProps {
  title: string;
  redText: string;
  subTitle: string;
  image: string;
}

const Conatiner = styled.div`
  border-radius: 0.28681rem;
  border: 1.147px solid #eee;
  background: #fff;
  max-width: 35rem;
  width: 100%;
  max-width: 35rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 2rem 1.75rem;
  margin-bottom: 1.5rem;
  @media ${mobile} {
    padding: 1.25rem 1.15rem;
  }
`;

const Title = styled(Text)`
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  text-align: left;
  @media ${mobile} {
    font-size: 1.125rem;
  }
`;

const Background = styled(DynamicImage)``;

export const CandleGraph: FC<CandleGraphProps> = ({
  title,
  subTitle,
  image,
}) => (
  <Conatiner>
    <Title>{title}</Title>
    <Background src={image} alt="background" />
  </Conatiner>
);
