import React from 'react';
import styled from 'styled-components';
import { mobile, tablet, useQuery } from 'styles/breakpoints';
import { DynamicImage, Text } from 'components';

import { useSelector } from 'react-redux';
import BreedIconSvg from 'assets/icons/breedIcon.svg';
import AgeIconSvg from 'assets/icons/ageIcon.svg';
import FemaleIconSvg from 'assets/icons/femaleIcon.svg';
import MaleIconSvg from 'assets/icons/maleIcon.svg';
import { AppState } from 'state/types';
// import BulldogImg from 'results/bulldogImg.png';
// import BreedBgSvg from 'assets/images/results/breedBg.svg'
import { breedsImages } from 'utils/breedsImages';

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 40px;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  border-radius: 24px;
  max-width: 560px;
  width: 100%;
`;
const DogSummaryContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100%;
  background: ${({ theme }) => theme.colors.light0};
  border-radius: 16px;
  gap: 1rem;
  padding: 2rem;
  max-width: 560px;
  min-width: 560px;
  box-shadow: ${({ theme }) => theme.shadows.cardShadow};
  @media ${tablet} {
    padding: 1.5rem 1.5rem 0 1.5rem;
    min-width: 100%;
    width: 100%;
  }
`;

const RowBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

const ContentWrapper = styled(RowBox)`
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 1rem;
  @media ${tablet} {
    flex-direction: column;
  }
`;

const SummaryInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media ${tablet} {
    width: 100%;
  }
`;

const SummaryInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3px;
`;

const BreedIcon = styled(BreedIconSvg)`
  height: 48px;
  width: 48px;
`;
const AgeIcon = styled(AgeIconSvg)`
  height: 48px;
  width: 48px;
`;
const FemaleIcon = styled(FemaleIconSvg)`
  height: 48px;
  width: 48px;
`;
const MaleIcon = styled(MaleIconSvg)`
  height: 48px;
  width: 48px;
`;

const ImageContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  width: 268px;
  height: 216px;
  @media ${mobile} {
    height: auto;
    width: 271px;
  }
`;

const StyledDynamicImage = styled(DynamicImage)`
  width: 268px;
  height: 216px;
  @media ${mobile} {
    width: 100%;
  }
` as typeof DynamicImage;

const DynamicImgBackground = styled(DynamicImage)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  max-height: 180px;
` as typeof DynamicImage;

const CapitalizedText = styled(Text)`
  text-transform: capitalize;
`;

const Title = styled(Text)`
  font-weight: 700;
  @media ${mobile} {
    font-size: 1.25rem;
  }
`;

type PersonalizedRoadTypes = {
  title?: string;
  defaultImg?: string;
};

const PersonalizedRoad = ({ title, defaultImg }: PersonalizedRoadTypes) => {
  const { isTablet } = useQuery();
  const quizAnswers = useSelector((state: AppState) => state.user.quiz_answers);
  const breedsList = useSelector((state: AppState) => state?.dogBreed?.list);

  const imgUrlbyBreed = breedsList.filter(
    item => item.label === quizAnswers.dogs_breed,
  );

  const isMale = quizAnswers?.gender === 'Boy' || quizAnswers?.gender === 'boy';

  const dogBreedKey = breedsList.find(
    item => item.label === quizAnswers?.dogs_breed && item?.hasImage,
  );

  return (
    <Container>
      <Content>
        <Title
          type={isTablet ? 'h2600' : 'h1S600'}
          textAlign="center"
          color="dark80"
        >
          {title}
        </Title>
        <DogSummaryContainer>
          <Text
            type={isTablet ? 'h3' : 'h3'}
            textAlign="center"
            color="dark100"
          >
            {quizAnswers?.userDogName || 'Your dog'} summary
          </Text>
          <ContentWrapper>
            <SummaryInfoContainer>
              <RowBox>
                <BreedIcon />
                <SummaryInfo>
                  <Text type="bodyS" color="dark60">
                    Breed
                  </Text>
                  <Text type="bodyM600" color="dark100">
                    {quizAnswers?.dogs_breed}
                  </Text>
                </SummaryInfo>
              </RowBox>

              <RowBox>
                {isMale ? <MaleIcon /> : <FemaleIcon />}
                <SummaryInfo>
                  <Text type="bodyS" color="dark60">
                    Sex
                  </Text>
                  <Text type="bodyM600" color="dark100">
                    {quizAnswers?.gender}
                  </Text>
                </SummaryInfo>
              </RowBox>

              <RowBox>
                <AgeIcon />
                <SummaryInfo>
                  <Text type="bodyS" color="dark60">
                    Age
                  </Text>
                  <Text type="bodyM600" color="dark100">
                    {quizAnswers?.dog_age}
                  </Text>
                </SummaryInfo>
              </RowBox>
            </SummaryInfoContainer>

            <ImageContainer>
              <DynamicImgBackground
                src={'results/breedBg.png'}
                alt="dog sumarry"
              />
              <StyledDynamicImage
                src={breedsImages(dogBreedKey?.value).url}
                alt="dog sumarry"
              />
            </ImageContainer>
          </ContentWrapper>
        </DogSummaryContainer>
      </Content>
    </Container>
  );
};

export default PersonalizedRoad;
