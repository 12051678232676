import { DynamicImage, Text } from 'components';
import React, { FC } from 'react';
import styled from 'styled-components';
import StartNowButton from './StartNowButton';
import { mobile, useQuery } from 'styles/breakpoints';
import { getDateLanguageString } from 'utils/dates';

interface TextImageCardButtonProps {
  title: string;
  image: string;
  chartImage: string;
  chartLanguage?: string;
  chartImage_mob: string;
  image_mob: string;
  card: {
    boldText: string;
    mainText: string;
  };
  buttonText: string;
  onClick: () => void;
}

const ImageWrapper = styled.div`
  position: relative;
`;
const MonthsLine = styled.div`
  position: absolute;
  right: 30px;
  bottom: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  max-width: 460px;
  @media ${mobile} {
    bottom: 15px;
    max-width: 260px;
  }
`;

const currentDate = new Date();
const currentMonth = currentDate.toLocaleString(getDateLanguageString(), {
  month: 'long',
});

const nextMonth = new Date();
nextMonth.setMonth(currentDate.getMonth() + 1);

const thirdMonth = new Date();

thirdMonth.setMonth(currentDate.getMonth() + 2);

const TextImageCardButton: FC<TextImageCardButtonProps> = ({
  title,
  image,
  image_mob,
  chartImage,
  chartLanguage,
  chartImage_mob,
  card,
  buttonText,
  onClick,
}) => {
  const { isMobile } = useQuery();
  return (
    <>
      <Container>
        <StyledTitle>{title}</StyledTitle>
        {(image || image_mob) && (
          <StyledImage src={isMobile ? image_mob : image} alt="chart_image" />
        )}

        {chartImage && chartImage_mob && (
          <ImageWrapper>
            <StyledImage
              src={isMobile ? chartImage_mob : chartImage}
              alt="chart_image"
            />
            <MonthsLine>
              <Text type={isMobile ? 'bodyXS' : 'bodyS'}>{currentMonth}</Text>
              <Text type={isMobile ? 'bodyXS' : 'bodyS'}>
                {nextMonth.toLocaleString(
                  getDateLanguageString(chartLanguage),
                  {
                    month: 'long',
                  },
                )}
              </Text>
              <Text type={isMobile ? 'bodyXS' : 'bodyS'}>
                {thirdMonth.toLocaleString(
                  getDateLanguageString(chartLanguage),
                  {
                    month: 'long',
                  },
                )}
              </Text>
            </MonthsLine>
          </ImageWrapper>
        )}
        <Card>
          <CardBoldText>{card.boldText}</CardBoldText>
          <CardMainText>{card.mainText}</CardMainText>
        </Card>
        <StyledButton onClick={onClick}>{buttonText}</StyledButton>
      </Container>
    </>
  );
};

export default TextImageCardButton;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 48px;
  @media ${mobile} {
    padding: 0 16px;
  }
`;

const StyledTitle = styled.h1`
  margin-bottom: 24px;
  @media ${mobile} {
    width: 343px;
    align-items: center;
    text-align: center;
    color: #28293d;
  }
`;

const StyledImage = styled(DynamicImage)`
  height: 402px;
  width: 100%;
  width: 560px;
  border: 1px solid #f7f7ff;
  box-shadow: 0px 4.93527px 16px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  @media ${mobile} {
    height: 100%;
    width: 100%;
    min-width: 343px;
    box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.06);
    border-radius: 16px;
  }
`;

const Card = styled.div`
  width: 560px;
  height: 136px;
  background: rgba(128, 77, 161, 0.08);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  @media ${mobile} {
    width: 100%;
    height: 220px;
    flex-direction: column;
    align-items: baseline;
  }
`;

const CardBoldText = styled.p`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 4rem;
  line-height: 72px;
  margin: 32px 0 32px 32px;
  color: #804da1;
  @media ${mobile} {
    font-size: 3rem;
    margin: 32px 0 0 32px;
  }
`;

const CardMainText = styled.p`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 28px;
  width: 333px;
  margin: 32px 32px 32px 0;
  color: #212429;
  @media ${mobile} {
    margin: 16px 32px 32px 32px;
    width: 279px;
  }
`;

const StyledButton = styled(StartNowButton)`
  margin-top: 24px;
  width: 560px;
  background: #804da1;
  border-radius: 100px;
  height: 56px;
  @media ${mobile} {
    width: 100%;
  }
`;
