import React, { useCallback } from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import { DynamicImage, Text } from 'components';
import Container from './ContentContainer';
import { smLaptop, tablet, useQuery } from 'styles/breakpoints';

interface Stat {
  score: string;
  text: string;
  textBold: string;
}

interface MembersStatisticsProps {
  statistics: Stat[];
  img: string;
  alt: string;
}

const Content = styled.div`
  display: flex;
  flex: 1;

  @media ${tablet} {
    flex-direction: column;
  }
`;

const StatisticsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  div:nth-child(1n):not(:last-child) {
    margin-bottom: 2rem;
  }
`;

const ImageWRapper = styled.div`
  height: 31.46rem;
  margin-right: 6rem;
  object-fit: contain;

  @media ${smLaptop} {
    height: 20.67rem;
    margin-right: 4rem;
  }

  @media ${tablet} {
    margin: 0 0 2rem 0;
  }
`;

const StatContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StatScore = styled(Text).attrs({
  color: 'orange100',
})`
  margin-right: 1rem;
`;

const TextStyled = styled(Text).attrs({
  color: 'dark80',
})``;

const Bold = styled.span`
  font-weight: 700;
  font-family: inherit;
  font-size: inherit;
`;

const MembersStatistics: FC<MembersStatisticsProps> = ({
  statistics,
  img,
  alt,
}) => {
  const { isMobile } = useQuery();

  const renderStatistic = useCallback(
    (item: Stat, index: number) => {
      const key = `stat-${index}`;
      return (
        <StatContainer key={key}>
          <StatScore type={isMobile ? 'h3' : 'h1'}>{item.score}</StatScore>
          <TextStyled type={isMobile ? 'body' : 'h4400'}>
            {item.text}
            <Bold>{item.textBold ? item.textBold : ''}</Bold>
          </TextStyled>
        </StatContainer>
      );
    },
    [isMobile],
  );

  return (
    <Container>
      <Content>
        <ImageWRapper>
          <DynamicImage src={img} alt={alt} />
        </ImageWRapper>
        <StatisticsContainer>
          {statistics.map(renderStatistic)}
        </StatisticsContainer>
      </Content>
    </Container>
  );
};

export default React.memo(MembersStatistics);
