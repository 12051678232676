import React from 'react';
import styled from 'styled-components';
import { tablet, useQuery } from 'styles/breakpoints';
import { Text } from 'components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 40px;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  border-radius: 1rem;
  width: 560px;
  max-width: 560px;
  gap: 2rem;
  padding: 2rem;
  box-shadow: ${({ theme }) => theme.shadows.cardShadow};
  background: ${({ theme }) => theme.colors.light0};
  @media ${tablet} {
    flex-direction: column;
    width: 100%;
    gap: 1rem;
    padding: 1.5rem;
  }
`;

const DecoratedText = styled(Text)`
  font-size: 4rem;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 800;
  line-height: 130%;
  @media ${tablet} {
    font-size: 3rem;
  }
`;

type SuccessfullyImprovedBadgeProps = {
  text: string;
  decoratedText: string;
};
const SuccessfullyImprovedBadge = ({
  text,
  decoratedText,
}: SuccessfullyImprovedBadgeProps) => {
  const { isTablet } = useQuery();

  return (
    <Container>
      <Content>
        <DecoratedText>{decoratedText}</DecoratedText>

        <Text type={isTablet ? 'body500' : 'h4500'} color="dark80">
          {text}
        </Text>
      </Content>
    </Container>
  );
};

export default SuccessfullyImprovedBadge;
