import React, { useCallback } from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import { mobile, tablet, useQuery } from 'styles/breakpoints';
import ContentContainer from './ContentContainer';
import CheckItem from 'components/CheckItem';
import { DynamicImage, Text } from 'components';
import help from 'assets/images/help.png';

interface HelpProps {
  title?: string;
  list?: string[];
}

const Wrap = styled(ContentContainer)`
  display: flex;
  flex-direction: column;
  position: relative;

  @media ${tablet} {
    margin: 0;
  }
`;

const Container = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  border-radius: 1.5rem;
  background-color: ${({ theme }) => theme.colors.orange40};
  margin-top: 5.5rem;
  overflow: hidden;
  max-height: 22.34rem;

  @media ${tablet} {
    position: relative;
    flex-direction: column;
    border-radius: 0;
    margin-top: 5.05rem;
    max-height: none;
    overflow: visible;
  }
`;

const Content = styled.div`
  flex: 1;
  height: 100%;
  padding: 3rem;
  background-color: ${({ theme }) => theme.colors.ochreLight};

  @media ${mobile} {
    padding: 1rem;
  }
`;

const ImageContainer = styled.div`
  width: 24.4rem;
  position: relative;

  @media ${tablet} {
    height: 15rem;
  }
`;

const ImageAbsContainer = styled.div`
  width: 100vw;
  position: absolute;
  bottom: 0;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
`;

const ImageWRapper = styled.div`
  height: 26.34rem;
  align-self: flex-end;
  z-index: 2;

  @media ${tablet} {
    height: 19.37rem;
  }
`;

const ListContainer = styled.div`
  margin-top: 2rem;
  div:nth-child(1n):not(:last-child) {
    margin-bottom: 1.66rem;
  }
`;

const Help: FC<HelpProps> = ({ list, title }) => {
  const { isTablet } = useQuery();

  const renderListItem = useCallback((item: string, index: number) => {
    const key = `help-list-item-${index}`;
    return <CheckItem key={key}>{item}</CheckItem>;
  }, []);

  return (
    <Wrap>
      <Container>
        {isTablet && (
          <ImageContainer>
            <ImageAbsContainer>
              <ImageWRapper>
                <DynamicImage src={help} alt="help icon" />
              </ImageWRapper>
            </ImageAbsContainer>
          </ImageContainer>
        )}
        <Content>
          <Text type={isTablet ? 'h2600' : 'h1'} color="dark100">
            {title}
          </Text>
          {list?.length && (
            <ListContainer>{list.map(renderListItem)}</ListContainer>
          )}
        </Content>
        {!isTablet ? (
          <ImageWRapper>
            <DynamicImage src={help} alt="help icon" />
          </ImageWRapper>
        ) : null}
      </Container>
      {!isTablet ? (
        <ImageWRapper>
          <DynamicImage src={help} alt="help icon" />
        </ImageWRapper>
      ) : null}
    </Wrap>
  );
};

export default React.memo(Help);
