import React from 'react';

import BoySVG from 'assets/icons/boy.svg';
import WeightSVG from 'assets/icons/current-weight.svg';
import HeightSVG from 'assets/icons/height.svg';
import styled from 'styled-components';
import Text from './Text';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';
import { tablet, useQuery } from 'styles/breakpoints';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const InnerContainer = styled.div`
  max-width: 580px;
  display: flex;
  flex-direction: row;
  width: 100%;
  box-shadow: 0px 4px 16px 0px #00000014;
  background-color: #ffffff;
  padding: 1.5rem 2rem;
  border-radius: 16px;
  justify-content: space-around;
  @media ${tablet} {
    justify-content: space-between;
  }
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Label = styled(Text)`
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;

  margin-top: 0.5rem;

  font-size: 24px;

  line-height: 34px;
  @media ${tablet} {
    font-size: 12px;
    line-height: 17px;
  }
`;

const Value = styled(Text)`
  font-weight: 700;
  letter-spacing: 0em;
  text-align: center;

  font-size: 24px;
  line-height: 34px;
  @media ${tablet} {
    font-size: 16px;
    line-height: 22px;
  }
`;

const WeightLossStats = ({ ...props }) => {
  const quiz_answers = useSelector(
    (state: AppState) => state.user?.quiz_answers,
  );
  const { isTablet } = useQuery();
  const currentWeight = quiz_answers?.current_weight || '';
  const currentWeightUnit = quiz_answers?.current_weight_unit || '';
  const { height_cm, height_ft, height_in, height_unit, age } =
    quiz_answers || {};

  const renderHeight = () => {
    if (height_unit === 'metric') {
      return `${height_cm} cm`;
    }
    return `${height_ft}ft ${height_in}in`;
  };

  return (
    <Container {...props}>
      <InnerContainer>
        <ItemContainer>
          <BoySVG width={isTablet ? 48 : 64} />
          <Label>Age</Label>
          <Value>{age} years</Value>
        </ItemContainer>
        <ItemContainer>
          <WeightSVG width={isTablet ? 48 : 64} />
          <Label>Weight</Label>
          <Value>{`${currentWeight} ${currentWeightUnit}`}</Value>
        </ItemContainer>
        <ItemContainer>
          <HeightSVG width={isTablet ? 48 : 64} />
          <Label>Height</Label>
          <Value>{renderHeight()}</Value>
        </ItemContainer>
      </InnerContainer>
    </Container>
  );
};

export default WeightLossStats;
