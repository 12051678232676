import { DynamicImage } from 'components';
import React, { FC } from 'react';
import styled from 'styled-components';
import { mobile } from 'styles/breakpoints';

interface PersonalSummaryProps {
  title: string;
  cardTitle: string;
  items: {
    icon: string;
    title: string;
    text: string;
  }[];
}

const PersonalSummary: FC<PersonalSummaryProps> = ({
  title,
  cardTitle,
  items,
}) => (
  <>
    <Container>
      <Title>{title}</Title>
      <CardContainer>
        <CardTitle>{cardTitle}</CardTitle>
        {items.map((item, index) => (
          <>
            <BreakLine />
            <Card key={index}>
              <CardIcon alt="icon" src={item.icon} />
              <InnerTextContainer>
                <InnerCardWrap>
                  <InnerCardTitle>{item.title}</InnerCardTitle>
                  <InnerCardResult>
                    <ResultText>Could be better</ResultText>
                    <ResultIcon alt="" src={'lastingChange/icons/Cross.png'} />
                  </InnerCardResult>
                </InnerCardWrap>
                <InnerCardText>{item.text}</InnerCardText>
              </InnerTextContainer>
            </Card>
          </>
        ))}
      </CardContainer>
    </Container>
  </>
);

export default PersonalSummary;

const Title = styled.p`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 130%;
  color: #28293d;
  margin-bottom: 24px;
`;

const BreakLine = styled.div`
  width: 560px;
  height: 0px;
  border: 1px solid rgba(40, 41, 61, 0.08);
  @media ${mobile} {
    width: 100%;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 48px;
  @media ${mobile} {
    padding: 0 16px;
  }
`;

const CardContainer = styled.div`
  height: 372px;
  width: 560px;
  background: rgba(128, 77, 161, 0.08);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${mobile} {
    width: 100%;
    height: 604px;
  }
`;

const CardTitle = styled.p`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  width: 322px;
  color: #212529;
  margin-bottom: 16px;
  @media ${mobile} {
    margin: 24px 0;
    font-size: 1rem;
    width: 295px;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: row;
  width: 496px;
  margin: 16px 0 16px;
  align-items: center;
  @media ${mobile} {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    height: 604px;
    margin: 24px 0 24px;
    padding: 0 32px;
  }
`;

const CardIcon = styled(DynamicImage)`
  width: 40px;
  height: 35px;
  margin-right: 24px;
`;

const ResultIcon = styled(DynamicImage)`
  height: 16px;
  width: 16px;
  margin-left: 4px;
`;

const ResultText = styled.p`
  color: #ff875a;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 130%;
  width: 93px;
`;

const InnerTextContainer = styled.div`
  display: flex;
  width: 432px;
  flex-direction: column;
  @media ${mobile} {
    width: 100%;
  }
`;

const InnerCardTitle = styled.p`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
`;

const InnerCardResult = styled.div`
  display: flex;
`;

const InnerCardText = styled.p``;

const InnerCardWrap = styled.div`
  display: flex;
  align-items: baseline;
  width: 100%;
  justify-content: space-between;
  @media ${mobile} {
    margin-top: 18px;
  }
`;
