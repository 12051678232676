import { Text } from 'components';
import WeightLossChart from 'components/WeightLossChart';
import React, { FC } from 'react';
import styled from 'styled-components';
import { mobile, tablet } from 'styles/breakpoints';

interface WeightLossChartComponentProps {
  title: string;
  label: string;
  isMain: boolean;
}

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const InnerContainer = styled.div`
  max-width: 560px;
  padding: 1rem 1rem 0 1rem;
  @media ${mobile} {
    padding: 1rem 1rem 0;
  }
`;

const Title = styled(Text)`
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 0.75rem;
  margin-top: 3rem;
  span {
    color: #05a56f;
  }
  @media ${tablet} {
    font-size: 24px;
    margin-top: 0.5rem;
    line-height: 31.2px;
  }
`;

const Label = styled(Text)<{ isMain: boolean }>`
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: ${({ isMain }) => (isMain ? '0' : '2rem')};
  @media ${tablet} {
    font-size: 1rem;
    line-height: 22.4px;
    margin-top: 0.5rem;
    margin-bottom: 0.75rem;
  }
`;

const WeightLossChartComponent: FC<WeightLossChartComponentProps> = ({
  title,
  label,
  isMain,
}) => (
  <Container>
    <InnerContainer>
      <Title dangerouslySetInnerHTML={{ __html: title }} />
      <Label isMain={isMain} dangerouslySetInnerHTML={{ __html: label }} />
      {isMain ? null : <WeightLossChart />}
    </InnerContainer>
  </Container>
);

export default WeightLossChartComponent;
