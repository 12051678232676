import React, { useCallback } from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import { Text } from 'components';
import { tablet } from 'styles/breakpoints';

interface Item {
  title: string;
  text: string;
}

interface HorizontalListProps {
  data: Item[];
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;

  div:nth-child(1n):not(:last-child) {
    margin-right: 1rem;
  }

  @media ${tablet} {
    flex-direction: column;

    div:nth-child(1n):not(:last-child) {
      margin: 0 0 1.5rem 0;
    }
  }
`;

const ItemContainer = styled.div`
  text-align: center;
  width: 15.56rem;

  @media ${tablet} {
    width: auto;
  }
`;

const TextStyled = styled(Text).attrs({
  type: 'body',
  color: 'dark100',
})`
  margin-top: 0.75rem;
`;

const HorizontalList: FC<HorizontalListProps> = ({ data }) => {
  const renderItem = useCallback((item: Item, index: number) => {
    const key = `description-3-item-${index}`;

    return (
      <ItemContainer key={key}>
        <Text type="h13Rem" color="dark100">
          {item.title}
        </Text>
        <TextStyled>{item.text}</TextStyled>
      </ItemContainer>
    );
  }, []);
  return <Container>{data.map(renderItem)}</Container>;
};

export default React.memo(HorizontalList);
