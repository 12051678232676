import React from 'react';
import type { FC } from 'react';
import styled from 'styled-components';

import { DynamicImage, Text } from 'components';

import { mobile, tablet, useQuery } from 'styles/breakpoints';

import img from 'assets/images/results/ourPromiseBg_des.png';
import img_mob from 'assets/images/results/ourPromiseBg_mob.png';

interface OurPromiseProps {
  title?: string;
  text?: string;
  bottomText?: string;
  bgImageDes?: string;
  bgImageMob?: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  @media ${tablet} {
  }
`;

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 560px;
  @media ${tablet} {
    gap: 1rem;
  }
`;

const Title = styled(Text).attrs({
  color: 'light0',
})`
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  text-align: center;
  @media ${mobile} {
    font-size: 1.5rem;
  }
`;

const StyledDynamicImage = styled(DynamicImage)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  min-width: 100%;
  z-index: -1;
  border-radius: 1rem;
  @media ${tablet} {
    height: 100%;
  }
`;

const StyledBottomText = styled(Text)`
  span {
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 140%;
  }
`;

const OurPromise: FC<OurPromiseProps> = ({
  title,
  text,
  bgImageDes,
  bgImageMob,
  bottomText,
}) => {
  const { isTablet, isMobile } = useQuery();

  return (
    <Container>
      <Title>{title}</Title>
      <Card>
        {text && (
          <Text
            type={isTablet ? 'body' : 'h4400'}
            color="light0"
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
        )}

        {bottomText && (
          <StyledBottomText
            type={isTablet ? 'body' : 'body'}
            color="light0"
            dangerouslySetInnerHTML={{
              __html: bottomText,
            }}
          />
        )}
      </Card>
    </Container>
  );
};

export default OurPromise;
